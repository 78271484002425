import * as React from 'react'
import { graphql, Link } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import Layout from '../../components/layout-journal-page'
import {
	card,
  siteSubtitle
} from '../../components/layout.module.css'

const BlogPost = ({ data }) => {
  return (
	<Layout pageTitle={data.mdx.frontmatter.title}>
		<h2 className={siteSubtitle}>{data.mdx.frontmatter.title}</h2>
		<div className={card}>
	  	<MDXRenderer>
				{data.mdx.body}
	  	</MDXRenderer>
		</div>
		<footer>
			<Link to={data.mdx.frontmatter.back}>Back</Link>
			<Link to={data.mdx.frontmatter.next}>Next</Link>
		</footer>
	</Layout>
  )
}

export const query = graphql`
query ($id: String) {
  mdx(id: {eq: $id}) {
	frontmatter {
	  title
	  date(formatString: "MMMM D, YYYY")
	  back
	  next
	}
	body
  }
}
`
export default BlogPost