import * as React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import {
  container,
  siteTitle,
	titleLink
} from './layout.module.css'


const Layout = ({ pageTitle, children }) => {
	const data = useStaticQuery(graphql`
		query {
		  site {
			siteMetadata {
			  title
			}
		  }
		}
	  `
  )
  return (
	<div className={container}>
	  <title>{pageTitle} | {data.site.siteMetadata.title}</title>
	  <header>
			<Link to="/this-seeming-chaos/read" className={titleLink}>
		  	<h1 className={siteTitle}>This<br/>Seeming<br/>Chaos</h1>
			</Link>
	   </header>
	  <main>
		{children}
	  </main>
	</div>
  )
}
export default Layout
